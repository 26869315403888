<template>
  <div class="mededeling">
    <div class="header-orange">
      <h1>Mededeling</h1>
    </div>
    <div v-if="announcement" class="mededeling-inhoud" v-html="announcement"></div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useFetch } from '../composables/useFetch';
import { useStore } from 'vuex';

export default ({
  name: 'Announcement',
  setup() {
    const store = useStore();
    const announcement = ref('');
    const theme = store.state.theme;
    const url = process.env.VUE_APP_ANNOUNCEMENT_ENDPOINT + "?board=" + theme
    useFetch(url).then((response) => response.text()).then((result) => {
      announcement.value = result;
    }).catch(() => {
      announcement.value = '<p>Geen</p>';
    });
    return { announcement }
  }

});
</script>