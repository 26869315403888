<template>

        <main class="vestigingall">
            <!-- <div class="b is-hidden-tablet">
                <TeamTargets :revenue="team_revenue" :target="team_target" :thisTeam="thisTeam" />
            </div> -->
            <div class="a1 tcol">
                <TeamCard  v-for="team in teams.slice(0, 3)" :key="team" :header="team" />
            </div>
            <!-- <div class="a3 block">
                <Reviews />
            </div> -->
            <div class="b is-hidden-mobile">
                <TeamTargets class="target-center" :revenue="team_revenue" :target="team_target" />
            </div>
            <div class="c1 tcol">
                <TeamCard v-for="team in teams.slice(3,5)" :key="team" :header="team" />
                <h4>CUMULATIEF PER UUR</h4>
                <Barchart v-if="chartHourCumm" class="bh" :toggle="toggle" :axis="chart1axis" :data="chartHourCumm"
                    :cnt_scale="200" :fee_scale="10000" />

            </div>

        </main>
        <Footer />

</template>

<script>
import { onMounted, computed, ref } from 'vue'
import Footer from '../components/Footer.vue';
import TeamCard from '../components/TeamCard.vue';
// import Reviews from '../components/Reviews.vue';
import TeamTargets from '../components/TeamTargets.vue';
import Barchart from '../components/Barchart.vue'
import { useStore } from 'vuex'
import shared from '../shared/shared.js'



export default {
    name: 'Team',
    components: {
        Footer,
        TeamCard,
        TeamTargets,
        Barchart
        // TeamCard,
        // Reviews,
        // TeamTargets
    },
    setup() {
        const store = useStore()
        const thisTeam = ref('');
        const toggle = ref(false);
        const toggle_timer = ref(false);



        const { formatFee, getItemComplianceClass, getRowComplianceClass, formatCompliance } = shared()
        const chart1axis = { x: ['09:00', '12:00', '13:00', '16:00', '19:00', '22:00'] }

        onMounted(function () {
            setupTeams()
            setupTimer()
        })

        const teams = computed(function () {
            return store.state.teams.filter((el) => el.toLowerCase() !== thisTeam.value.toLowerCase());
        })



        const team_revenue = computed(function () {

            const all_revenue = store.getters.getSummaryTeams.reduce((acc,cur) => {
                acc.fee_this_year += cur.fee_this_year
                acc.cnt_this_year += cur.cnt_this_year
                acc.fee_this_quarter += cur.fee_this_quarter
                acc.cnt_this_quarter += cur.cnt_this_quarter
                acc.fee_this_month += cur.fee_this_month
                acc.cnt_this_month += cur.cnt_this_month
                acc.fee_this_week += cur.fee_this_week
                acc.cnt_this_week += cur.cnt_this_week
                acc.fee_this_half_year += cur.fee_this_half_year
                acc.cnt_this_half_year += cur.cnt_this_half_year
                acc.fee_today += cur.fee_today
                acc.cnt_today += cur.cnt_today
                return acc
            },{
                fee_this_year: 0.0,
                cnt_this_year: 0,
                fee_this_quarter: 0.0,
                cnt_this_quarter:0,
                fee_this_month: 0,
                cnt_this_month: 0,
                fee_this_week: 0.0,
                cnt_this_week: 0,
                fee_this_half_year: 0.0,
                cnt_this_half_year: 0,
                fee_today: 0.0,
                cnt_today: 0
            })


            console.log('all_revenue', all_revenue)
            return all_revenue;
        })

        const team_target = computed(function () {
            const all_targets = store.getters.getTargets.reduce((acc, cur) => {
                acc.iso_month = cur.iso_month
                acc.iso_week = cur.iso_week
                acc.iso_year = cur.iso_year
                acc.team = cur.team
                acc.month_target += cur.month_target
                acc.quarter_target += cur.quarter_target
                acc.week_target += cur.week_target
                acc.year_target += cur.year_target
                acc.half_year_target += cur.half_year_target
                return acc
            },
            {
                iso_month: 0,
                iso_week: 0,
                iso_year: 0,
                month_target: 0,
                quarter_target: 0,
                team: thisTeam.value.toLowerCase(),
                week_target: 0,
                year_target: 0,
                half_year_target: 0
            })
            return all_targets
        })


        // the base data is managed in the store, this are the detail filters
        // const chartHour = computed(function () {
        //     const today = store.getters.getChartToday

        //     const hour9last = store.getters.getChartHour.filter((el) => el.global_week != today.global_week && el.hour > 0.0 && el.hour <= 9.0).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
        //     const hour9current = store.getters.getChartHour.filter((el) => el.global_week == today.global_week && el.hour > 0.0 && el.hour <= 9.0).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
        //     const hour12last = store.getters.getChartHour.filter((el) => el.global_week != today.global_week && el.hour > 9.0 && el.hour <= 12.0).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
        //     const hour12current = store.getters.getChartHour.filter((el) => el.global_week == today.global_week && el.hour > 9.0 && el.hour <= 12.0).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
        //     const hour13last = store.getters.getChartHour.filter((el) => el.global_week != today.global_week && el.hour > 12.0 && el.hour <= 13.0).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
        //     const hour13current = store.getters.getChartHour.filter((el) => el.global_week == today.global_week && el.hour > 12.0 && el.hour <= 13.0).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
        //     const hour16last = store.getters.getChartHour.filter((el) => el.global_week != today.global_week && el.hour > 13.0 && el.hour <= 16.0).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
        //     const hour16current = store.getters.getChartHour.filter((el) => el.global_week == today.global_week && el.hour > 13.0 && el.hour <= 16.0).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
        //     const hour19last = store.getters.getChartHour.filter((el) => el.global_week != today.global_week && el.hour > 16.0 && el.hour <= 19.0).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
        //     const hour19current = store.getters.getChartHour.filter((el) => el.global_week == today.global_week && el.hour > 16.0 && el.hour <= 19.0).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
        //     const hour22last = store.getters.getChartHour.filter((el) => el.global_week != today.global_week && el.hour > 19.0 && el.hour <= 24.0).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
        //     const hour22current = store.getters.getChartHour.filter((el) => el.global_week == today.global_week && el.hour > 19.0 && el.hour <= 24.0).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })

        //     const ar = [hour9last, hour9current, hour12last, hour12current, hour13last, hour13current, hour16last, hour16current, hour19last, hour19current, hour22last, hour22current]
        //     const max_fee = Math.max.apply(Math, ar.map(function (el) { return el.fee_partner; }))
        //     const max_cnt = Math.max.apply(Math, ar.map(function (el) { return el.cnt; }))
        //     // console.log('chart_hour',max_fee,max_cnt)

        //     return {
        //         values: [
        //             { data1: hour9current, data2: hour9last },
        //             { data1: hour12current, data2: hour12last },
        //             { data1: hour13current, data2: hour13last },
        //             { data1: hour16current, data2: hour16last },
        //             { data1: hour19current, data2: hour19last },
        //             { data1: hour22current, data2: hour22last }
        //         ], max_fee: max_fee, max_cnt: max_cnt
        //     }
        // })

        const chartHourCumm = computed(function () {

            const today = store.getters.getChartToday
            // console.log(today)
            if (store.state.current_time != null) {

                const current_hour = store.state.current_time.split(':')[0]

                const hour9last = store.getters.getChartHour.filter((el) => el.global_week != today.global_week && el.hour > 0 && el.hour <= 9).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
                const hour9current = store.getters.getChartHour.filter((el) => el.global_week == today.global_week && el.hour > 0 && el.hour <= 9).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
                // console.log('9',hour9last)

                const data9current = current_hour >= 9 ? { 'cnt': hour9current.cnt, 'fee_partner': hour9current.fee_partner } : { 'cnt': 0, 'fee_partner': 0.0 }
                const hour12last = store.getters.getChartHour.filter((el) => el.global_week != today.global_week && el.hour > 9 && el.hour <= 12).reduce(reducer, { 'cnt': hour9last.cnt, 'fee_partner': hour9last.fee_partner })
                const hour12current = store.getters.getChartHour.filter((el) => el.global_week == today.global_week && el.hour > 9 && el.hour <= 12).reduce(reducer, data9current)
                // console.log('12',hour12last)

                const data12current = current_hour >= 12 ? { 'cnt': hour12current.cnt, 'fee_partner': hour12current.fee_partner } : { 'cnt': 0, 'fee_partner': 0.0 }
                const hour13last = store.getters.getChartHour.filter((el) => el.global_week != today.global_week && el.hour > 12 && el.hour <= 13).reduce(reducer, { 'cnt': hour12last.cnt, 'fee_partner': hour12last.fee_partner })
                const hour13current = store.getters.getChartHour.filter((el) => el.global_week == today.global_week && el.hour > 12 && el.hour <= 13).reduce(reducer, data12current)
                // console.log('13',hour13last)

                const data13current = current_hour >= 13 ? { 'cnt': hour13current.cnt, 'fee_partner': hour13current.fee_partner } : { 'cnt': 0, 'fee_partner': 0.0 }
                const hour16last = store.getters.getChartHour.filter((el) => el.global_week != today.global_week && el.hour > 13 && el.hour <= 16).reduce(reducer, { 'cnt': hour13last.cnt, 'fee_partner': hour13last.fee_partner })
                const hour16current = store.getters.getChartHour.filter((el) => el.global_week == today.global_week && el.hour > 13 && el.hour <= 16).reduce(reducer, data13current)
                // console.log('16',hour16last)
                // console.log('testing', hour16current, hour12current, hour13current)

                const data16current = current_hour >= 16 ? { 'cnt': hour16current.cnt, 'fee_partner': hour16current.fee_partner } : { 'cnt': 0, 'fee_partner': 0.0 }
                const hour19last = store.getters.getChartHour.filter((el) => el.global_week != today.global_week && el.hour > 16 && el.hour <= 19).reduce(reducer, { 'cnt': hour16last.cnt, 'fee_partner': hour16last.fee_partner })
                const hour19current = store.getters.getChartHour.filter((el) => el.global_week == today.global_week && el.hour > 16 && el.hour <= 19).reduce(reducer, data16current)
                // console.log('19',hour16last)

                const data19current = current_hour >= 19 ? { 'cnt': hour19current.cnt, 'fee_partner': hour19current.fee_partner } : { 'cnt': 0, 'fee_partner': 0.0 }
                const hour22last = store.getters.getChartHour.filter((el) => el.global_week != today.global_week && el.hour > 19 && el.hour <= 24).reduce(reducer, { 'cnt': hour19last.cnt, 'fee_partner': hour19last.fee_partner })
                const hour22current = store.getters.getChartHour.filter((el) => el.global_week == today.global_week && el.hour > 19 && el.hour <= 24).reduce(reducer, data19current)


                const ar = [hour9last, hour9current, hour12last, hour12current, hour13last, hour13current, hour16last, hour16current, hour19last, hour19current, hour22last, hour22current]
                const max_fee = Math.max.apply(Math, ar.map(function (el) { return el.fee_partner; }))
                const max_cnt = Math.max.apply(Math, ar.map(function (el) { return el.cnt; }))
                // console.log('chart_hour_cumm',max_fee,max_cnt)

                return {
                    values: [
                        { data1: hour9current, data2: hour9last },
                        { data1: hour12current, data2: hour12last },
                        { data1: hour13current, data2: hour13last },
                        { data1: hour16current, data2: hour16last },
                        { data1: hour19current, data2: hour19last },
                        { data1: hour22current, data2: hour22last }
                    ], max_fee: max_fee, max_cnt: max_cnt
                }

            } else {
                return []
            }


        })

        function reducer(acc, cur) {
            acc.cnt += cur.cnt
            acc.fee_partner += cur.fee_partner
            return acc;
        }


        function setupTeams() {
            const url_team = new URL(window.location.href).searchParams.get('team');
            if (url_team) {
                thisTeam.value = url_team
            }
            store.dispatch('setTeams', process.env.VUE_APP_TEAMS_ALL.split(','));
        }

        function setupTimer() {
            if (toggle_timer.value === false) {
                toggle_timer.value = setInterval(() => {
                    toggle.value = !toggle.value
                }, 12000);
            }
        }

        return { teams, thisTeam, team_revenue, team_target, formatFee, getItemComplianceClass, getRowComplianceClass, formatCompliance, toggle ,chartHourCumm, chart1axis}
    },
}
</script>
