<template>
    <div v-if="reviews">
      <div class="col-header">
          <h1>Klanttevredenheid</h1>
      </div>
      <div v-for="(review, index) in reviews" :key="review.date_created">
        <div v-show="index === active_review - 1">
          <div class="reviews">
              <div class="partner-foto" :class="chooseColor(review.first_name.length + review.last_name.length)">{{review.initials}}</div>
              <h2>{{review.first_name}} {{review.last_name}}</h2>
              <div class="rating">
                  <span class="fa fa-star" :class="{'fa-star-half-o': review.score <= 0.5, checked: Math.ceil(review.score) >= 1}"></span>
                  <span class="fa fa-star" :class="{'fa-star-half-o': review.score <= 1.5, checked: Math.ceil(review.score) >= 2}"></span>
                  <span class="fa fa-star" :class="{'fa-star-half-o': review.score <= 2.5, checked: Math.ceil(review.score) >= 3}"></span>
                  <span class="fa fa-star" :class="{'fa-star-half-o': review.score <= 3.5, checked: Math.ceil(review.score) >= 4}"></span>
                  <span class="fa fa-star" :class="{'fa-star-half-o': review.score <= 4.5, checked: Math.ceil(review.score) >= 5}"></span>
              </div>
              <p>{{capitalize(review.reason)}}</p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, onMounted, ref } from 'vue'
import shared from '../shared/shared.js'

export default {
  name: 'Reviews',
  setup() {
    
    const store = useStore();
    const { getInitials, chooseColor, capitalize} = shared()

    let timer = false;
    const active_review = ref(1);

    onMounted(function() {
      if (timer === false) {
        setTimer()
      }
    })

    function setTimer() {
      timer = setInterval(function() {
        if (active_review.value === reviews.value.length) {
          active_review.value = 1
        } else {
          active_review.value += 1
        }
      }, 8000);
    }

    const reviews = computed(function() {
      return store.state.reviews.map((item) => {
        const contact = store.state.contacts.find((el) => el.ext_sf_partner_id === item.partner_id);
          if (contact) {
            const initials = getInitials(contact.first_name, contact.last_name)
            return { first_name: contact.first_name, last_name: contact.last_name, initials: initials, score: item.score, reason: item.reason }
          }
        }).filter((item) => !!item); //filters null
      }
    );
    return { reviews: reviews, active_review, capitalize, chooseColor }
  }
};
</script>
