<template>
    <div class="a1 block" v-if="team">
        <div class="col-header">
            <h1>{{team.team}}</h1>
        </div>
        <div class="location">
            <div class="scope">Vandaag</div>
            <div class="revenue-today">€ {{formatFee(team.fee_today)}}</div>
            <div class="scope">Week</div>
            <div class="revenue-week">€ {{formatFee(team.fee_this_week)}}</div>
            <div class="scope">Maand</div>
            <div class="revenue-month">€ {{formatFee(team.fee_this_month)}}</div>
            <div class="scope">Kwartaal</div>
            <div class="revenue-month">€ {{formatFee(team.fee_this_quarter)}}</div>
            <div class="scope">Halfjaar</div>
            <div class="revenue-month">€ {{formatFee(team.fee_this_half_year)}}</div>
        </div>
    </div>
</template>

<script>
import shared from '../shared/shared.js';
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
  name: 'TeamCard',
  props: [
    'header',
  ],
  setup(props) {
    const store = useStore()
    const { formatFee } = shared()

    const team = computed(function() {
      return store.getters.getSummaryTeams.find((el) => el.team.toLowerCase() == props.header.toLowerCase())
    })
    
    return { formatFee, team }
  }
};
</script>
