<template>
  <div class="revenue-widget">
    <div class="b2-1">
      <div class="col-header">
        <h1>{{ header }}</h1>
      </div>
      <div class="revenue-overview">
        <h2 class="scope">Vandaag</h2>
        <div class="revenue-day total">&euro;{{ formatFee(revenue.today) }}</div>
      </div>
    </div>
    <div class="b2-2">
      <div class="col-header">
        <h1>Doelstellingen bruto partneromzet</h1>
        <!-- <h2>UIT TE BETALEN PARTNEROMZET</h2> -->
      </div>

      <div class="revenue-overview">

        <h2 v-show="nav_toggle" class="scope">Week</h2>
        <h2 v-show="nav_toggle" class="scope">Maand</h2>
        <h2 v-show="!nav_toggle" class="scope">Kwartaal</h2>
        <h2 v-show="!nav_toggle" class="scope">Halfjaar</h2>

        <Chart v-if="nav_toggle" :revenue="revenue.week" :target="targets.week_target" />
        <Chart v-if="nav_toggle" :revenue="revenue.month" :target="targets.month_target" />
        <Chart v-if="!nav_toggle" :revenue="revenue.quarter" :target="targets.quarter_target" />
        <Chart v-if="!nav_toggle" :revenue="revenue.half_year" :target="targets.half_year_target" />

        <div v-if="nav_toggle">
          <h2 class="revenue">&euro;{{ formatFee(revenue.week) }}</h2>
          <p>van</p>
          <h2 class="revenue-target">&euro;{{ formatFee(targets.week_target) }}</h2>
        </div>

        <div v-if="nav_toggle">
          <h2 class="revenue">&euro;{{ formatFee(revenue.month) }}</h2>
          <p>van</p>
          <h2 class="revenue-target">&euro;{{ formatFee(targets.month_target) }}</h2>
        </div>

        <div v-if="!nav_toggle">
          <h2 class="revenue">&euro;{{ formatFee(revenue.quarter) }}</h2>
          <p>van</p>
          <h2 class="revenue-target">&euro;{{ formatFee(targets.quarter_target) }}</h2>
        </div>

        <div v-if="!nav_toggle">
          <h2 class="revenue">&euro;{{ formatFee(revenue.half_year) }}</h2>
          <p>van</p>
          <h2 class="revenue-target">&euro;{{ formatFee(targets.half_year_target) }}</h2>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Mixin from '../shared/mixin';
import shared from '../shared/shared.js';
import Chart from '../components/Chart.vue'
import { useStore } from 'vuex'
import { computed } from 'vue'

// import { defineComponent, computed, ref, onMounted, props } from 'vue'

export default {
  components: { Chart },
  name: 'Revenue',
  mixins: [Mixin],
  props: [
    'team',
    'header',
    'toggle'
  ],
  setup(props) {
    const store = useStore()
    const { getProgress, getStrokeDashArray } = shared()


    const targets = computed(function () {
      // console.log('targets', store.getters.getTargetsSum)
      // this is already filtered to exclude NULL team (advertiser based)
      return store.getters.getTargetsSum
    })

    const nav_toggle = computed(function () {
      return props.toggle
    })

    const revenue = computed(function () {
      const tot = store.getters.totalAll()
      return {
        today: tot.fee_today,
        week: tot.fee_this_week,
        month: tot.fee_this_month,
        quarter: tot.fee_this_quarter,
        year: tot.fee_this_year,
        half_year: tot.fee_this_half_year
      }
    })

    return { targets, revenue, nav_toggle, getProgress, getStrokeDashArray }
  }
};

</script>
