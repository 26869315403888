<template>
  <div class="row-vertical">
    <div v-if="vertical.data.length" :class="{ 'col-header': vertical.vertical == 'energy' || vertical.vertical == 'charity', 'cmlt': vertical.vertical == 'charity'}">
      <h2 v-if="vertical.vertical == 'energy'">{{ vertical.vertical }}</h2>
      <h2 v-if="vertical.vertical == 'charity'">Charity - Media - Lottery - Telecom</h2>
      <template v-if="row === 0 && activeSlide < 3">
        <div class="revenue-today">Vandaag</div>
        <div class="revenue-week">Week</div>
        <div class="revenue-month">Maand</div>
      </template>
      <template v-if="row === 0 && activeSlide > 2">
        <div class="revenue-today">Vandaag</div>
        <div class="revenue-week">Kwartaal</div>
        <div class="revenue-month">Halfjaar</div>
      </template>
    </div>
    <div v-for="item in vertical.data" :key="item.advertiser" class="row-adverteerder">
      <img class="logo" :src="item.logo_url" />
      <div class="adverteerder">{{ item.advertiser_name }}</div>
      <template v-if="activeSlide === 1">
        <div class="revenue-today">&euro;{{ formatFee(item.fee_today) }}</div>
        <div class="revenue-week">&euro;{{ formatFee(item.fee_this_week) }}</div>
        <div class="revenue-month">&euro;{{ formatFee(item.fee_this_month) }}</div>
      </template>
      <template v-if="activeSlide === 2">
        <div class="revenue-today">{{ item.cnt_today }}</div>
        <div class="revenue-week">{{ item.cnt_this_week }}</div>
        <div class="revenue-month">{{ item.cnt_this_month }}</div>
      </template>
      <template v-if="activeSlide === 3">
        <div class="revenue-today">&euro;{{ formatFee(item.fee_today) }}</div>
        <div class="revenue-week">&euro;{{ formatFee(item.fee_this_quarter) }}</div>
        <div class="revenue-month">&euro;{{ formatFee(item.fee_this_half_year) }}</div>
      </template>
      <template v-if="activeSlide === 4">
        <div class="revenue-today">{{ item.cnt_today }}</div>
        <div class="revenue-week">{{ item.cnt_this_quarter }}</div>
        <div class="revenue-month">{{ item.cnt_this_half_year }}</div>
      </template>

    </div>
  </div>
</template>

<script>
import Mixin from '../shared/mixin';

export default {
  name: 'AdvertiserVertical',
  mixins: [Mixin],
  props: [
    'vertical',
    'activeSlide',
    'cp',
    'row'
  ],

  mounted() {
    // console.log(this.$store.state.summary_advertisers)
  }
};
</script>
