<template>

                <div class="chart-container">
                <div v-show="toggle == false" class="scale">&euro; {{formatFee(scale_for_value(data.max_fee))}}</div>
                <div v-show="toggle == true" class="scale">{{scale_for_value(data.max_cnt)}}</div>
                <ul class="bar-chart">              
                    <template v-for="(item,index) in data.values" :key="item">
                        <li v-show="toggle == false" class="current-week fee">
                            <span :style="getPct(item.data1.fee_partner, scale_for_value(data.max_fee))" :title="getTitle(index)"></span>
                        </li>
                        <li v-show="toggle == false" class="past-week fee">
                            <span :style="getPct(item.data2.fee_partner, scale_for_value(data.max_fee))"></span>
                        </li>
                        <li v-show="toggle == true" class="current-week fee">
                            <span :style="getPct(item.data1.cnt, scale_for_value(data.max_cnt))" :title="getTitle(index)"></span>
                        </li>
                        <li v-show="toggle == true" class="past-week fee">
                            <span :style="getPct(item.data2.cnt,  scale_for_value(data.max_cnt))"></span>
                        </li>

                    </template>

                </ul>
                </div>

</template>

<script>
import shared from '../shared/shared.js';

export default {
    props: ['axis', 'data','max_val','cnt_scale', 'fee_scale', 'toggle'],
    name: 'Barchart',
    setup(props) {
      
        const { scale_for_value, formatFee } = shared()
        function getTitle(index) {
            return props.axis.x[index]
        }

        function getPct(val, scale) {
           return `height:${Math.min(Math.ceil((val/scale)*100),100)}%`
        }

        return { getTitle, getPct , scale_for_value, formatFee}

    }
}
</script>