<!-- REVENUE BLOCK doesn't have euro's (for charity). TODO make this hybrid-->

<template>
  <div class="revenue-block">
    <h1 class="center">{{ header }}</h1>
    <div>
          <h2 class="scope center">Vandaag</h2>
          <br />
          <h2 class="revenue center"><span v-if="showEuro">&euro;</span>{{ revenue.today }}</h2>
    </div>
    <div v-show="!toggle" class="grid-2-cols center rvb-margin">
      <div>
        <h2 v-if="!headerLow" class="scope">Week</h2>
        <Chart :revenue="revenue.week" :target="targets.week_target" class="center-auto"/>
        <div>
          <h2 v-if="headerLow" class="scope">Week</h2>
          <h2 class="revenue">{{ revenue.week }}</h2>
          <p>van</p>
          <h2 class="revenue-target">{{ targets.week_target }}</h2>
        </div>
      </div>
      <div class="center">
        <h2 v-if="!headerLow" class="scope">Maand</h2>
        <Chart :revenue="revenue.month" :target="targets.month_target" class="center-auto"/>
        <div>
          <h2 v-if="headerLow" class="scope">Maand</h2>
          <h2 class="revenue">{{ revenue.month }}</h2>
          <p>van</p>
          <h2 class="revenue-target">{{ targets.month_target }}</h2>
        </div>
      </div>
    </div>
    <div v-show="toggle" class="grid-2-cols center rvb-margin">
      <div>
        <h2 class="scope">Kwartaal</h2>
        <Chart :revenue="revenue.quarter" :target="targets.quarter_target" class="center-auto"/>
        <div>
          <h2 class="revenue">{{ revenue.quarter }}</h2>
          <p>van</p>
          <h2 class="revenue-target">{{ targets.quarter_target }}</h2>
        </div>
      </div>
      <div>
        <h2 class="scope">Halfjaar</h2>
        <Chart :revenue="revenue.half_year" :target="targets.half_year_target" class="center-auto"/>
        <div>
          <h2 class="revenue">{{ revenue.half_year }}</h2>
          <p>van</p>
          <h2 class="revenue-target">{{ targets.half_year_target }}</h2>
        </div>
      </div>
    </div>

  </div>
</template>

<script>


import shared from '../shared/shared.js';
import Chart from '../components/Chart.vue'
// import { onMounted } from 'vue'
export default {
  components: { Chart },
  name: 'Revenue',
  // mixins: [Mixin],
  // props: [
  //   'header',
  //   'toggle',
  //   'revenue',
  //   'targets',
  //   'grid' {
  //     grid-3-cols
  // ],
  props: {
    header: {},
    toggle: {},
    revenue: {},
    targets: {},
    showEuro: {
      type: Boolean,
      required: false,
      default: true
    },
    headerLow: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    // const store = useStore()

    const { getProgress, getStrokeDashArray, formatFee } = shared()



    return { getProgress, getStrokeDashArray, formatFee }
  }
};

</script>

<style>
.center-auto {
  margin: auto;
}
</style>
