import store from "@/store";
import { computed } from "vue";

export default function useTargets(certifiedPartnerId = null) {
  console.log("cp", certifiedPartnerId);
  const targets = computed(function() {
    let start;
    if (certifiedPartnerId) {
      start = store.getters.getTargets.filter(
        (el) => el.certified_partner_id === certifiedPartnerId
      );
    } else {
      start = store.getters.getTargets;
    }
    const retval = start.reduce((acc, cur) => {
      let advertiser = store.state.advertisers.find(
        (el) => el.code === cur.advertiser
      );
      let targets = {
        week_target: cur.week_target,
        month_target: cur.month_target,
        quarter_target: cur.quarter_target,
        half_year_target: cur.half_year_target,
      };
      let scores = store.getters
        .getSummaryAdvertisers(certifiedPartnerId)
        .filter((el) => el.advertiser === cur.advertiser)
        .map((el) => {
          return {
            week: el.cnt_this_week,
            month: el.cnt_this_month,
            quarter: el.cnt_this_quarter,
            half_year: el.cnt_this_half_year,
          };
        });
      console.log(advertiser);
      acc.push({
        advertiser: "",
        targets: targets,
        scores:
          scores && scores.length > 0
            ? scores[0]
            : { week: 0, month: 0, quarter: 0, half_year: 0 },
      });
      return acc;
    }, []);
    return retval;
  });

  return { targets };
}
