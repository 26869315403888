<script>
  import Chart from '../components/Chart.vue';
  import shared from '../shared/shared.js';

  export default {
    components: { Chart },
    setup() {
      const { formatFee } = shared();
      return { formatFee };
    },
    props: {
      target: {
        type: Object,
        required: false,
      },
      revenue: {
        type: Object,
        required: false,
      },
      thisTeam: {
        type: String,
        required: false,
      },
    },
  };
</script>

<template>
  <div v-if="revenue && target" class="team-t">
    <h1 class="team-l">Doelstellingen {{ thisTeam }} <span v-if="thisTeam && thisTeam == 'Cherries'"></span></h1>
    <h2 v-if="thisTeam && thisTeam != 'Cherries'" class="team-l">Uit te betalen partneromzet</h2>
    <h2 v-if="thisTeam && thisTeam == 'Cherries'" class="team-l">Partneromzet</h2>
    <div class="team-target-revenue-today" style="padding-top: 30px">
      <h2 class="scope">Vandaag</h2>
      <br />
      <h2 class="revenue">&euro;{{ formatFee(revenue.fee_today) }}</h2>
    </div>
    <div class="charts">
      <div class="achart">
        <h2 class="scope">Week</h2>
        <Chart
          v-show="true"
          :revenue="revenue.fee_this_week"
          :target="target.week_target"
        />
        <h2 class="revenue">&euro;{{ formatFee(revenue.fee_this_week) }}</h2>
        <p>van</p>
        <h2 class="revenue-target">
          &euro;{{ formatFee(target.week_target) }}
        </h2>
      </div>
      <div class="achart">
        <h2 class="scope">Maand</h2>
        <Chart
          v-show="true"
          :revenue="revenue.fee_this_month"
          :target="target.month_target"
        />
        <h2 class="revenue">&euro;{{ formatFee(revenue.fee_this_month) }}</h2>
        <p>van</p>
        <h2 class="revenue-target">
          &euro;{{ formatFee(target.month_target) }}
        </h2>
      </div>
      <div class="achart">
        <h2 class="scope">Kwartaal</h2>
        <Chart
          v-show="true"
          :revenue="revenue.fee_this_quarter"
          :target="target.quarter_target"
        />
        <h2 class="revenue">&euro;{{ formatFee(revenue.fee_this_quarter) }}</h2>
        <p>van</p>
        <h2 class="revenue-target">
          &euro;{{ formatFee(target.quarter_target) }}
        </h2>
      </div>
      <div class="achart">
        <h2 class="scope">Halfjaar</h2>
        <Chart
          v-show="true"
          :revenue="revenue.fee_this_half_year"
          :target="target.half_year_target"
        />
        <h2 class="revenue">
          &euro;{{ formatFee(revenue.fee_this_half_year) }}
        </h2>
        <p>van</p>
        <h2 class="revenue-target">
          &euro;{{ formatFee(target.half_year_target) }}
        </h2>
      </div>
    </div>
  </div>
</template>

<style>
.team-l {
  align-self: flex-start;
  margin-top: 5px;
  margin-left: 5px;
}

</style>