import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Keycloak from "keycloak-js";

const theme = new URL(window.location.href).searchParams.get("theme");

let head = document.querySelector("head");
let stylesheet = document.createElement("link");
// Set some attributes:
stylesheet.setAttribute("rel", "stylesheet");
stylesheet.setAttribute("type", "text/css");

let favi = document.querySelector("link[rel~='icon']");

if (!theme || theme === "improvers") {
  stylesheet.setAttribute("href", "css/theme-improvers.css"); // Your .css File
  favi.href =
    "https://improvers-prod-images-bucket.s3.eu-central-1.amazonaws.com/logo/rgb_im_beeldmerk_square_darkblue.svg";
} else {
  stylesheet.setAttribute("href", "css/theme-" + theme + ".css"); // Your .css File
  favi.href =
    "https://improvers-prod-images-bucket.s3.eu-central-1.amazonaws.com/logo/rgb_" +
    theme +
    "_beeldmerk_square_darkpurple.svg";
}

head.appendChild(stylesheet);

const token = new URL(window.location.href).searchParams.get("token");
let interval = false;
if (token) {
  createApp(App)
    .use(store)
    .use(router)
    .mount("#app");
} else {
  useKeycloak();
}

function useKeycloak() {
    const config = {
        url: process.env.VUE_APP_KEYCLOACK_URL || "https://35.157.183.54:8443/auth",
        realm: process.env.VUE_APP_KEYCLOACK_REALM || "demo",
        clientId: process.env.VUE_APP_KEYCLOACK_CLIENT_ID || "invoicer",
    };

    const initOptions = {
        onLoad: "login-required",
    }

    const keycloak = new Keycloak(config);

    keycloak
        .init(initOptions)
        .then((auth) => {
            if (!auth) {
                keycloak.login();
            } else {
                console.info("Authenticated");
                store.dispatch("setAccessToken", keycloak.token);
                const app = createApp(App, { keycloak });
                app
                    .use(store)
                    .use(router)
                    .mount("#app");

                refreshInterval(keycloak);
            }
        })
        .catch((ex) => {
            console.error(ex);
            console.error("Authenticated Failed");
        });
}

function refreshInterval(keycloak) {
    updateToken(keycloak);
    if (interval === false) {
        interval = setInterval(() => {
            updateToken(keycloak);
        }, 60000);
    }
}

function updateToken(keycloak) {
    keycloak
        .updateToken(70)
        .then((refreshed) => {
            if (refreshed) {
                store.dispatch("setAccessToken", keycloak.token);
                console.info("Token refreshed" + refreshed);
            } else {
                console.warn(
                    "Token not refreshed, valid for " +
                    Math.round(
                        keycloak.tokenParsed.exp +
                        keycloak.timeSkew -
                        new Date().getTime() / 1000
                    ) +
                    " seconds"
                );
            }
        })
        .catch(() => {
            console.error("Failed to refresh token");
        });
}
