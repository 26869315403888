<template>
  <div id="partner-big">
    <main class="top-partners">


      <div class="a block">
        <div class="col-header">
          <h1>Top Partners</h1>
          <div class="revenue-today">vandaag</div>
          <div class="revenue-week">week</div>
          <div class="revenue-quarter">kwartaal</div>
        </div>
        <div class="item">
        <transition-group name="list-partner">
          <PartnerList2 v-for="(item, index) in all_a" :key="item.partner_id" :toggle="nav_toggle" :item="item"
            :index="index" :rank="0" class="row-partners list-partner-item"
            :class="getRowComplianceClass(item.compliance)" />
        </transition-group>
        </div>
      </div>

      <div class="b block">
        <Revenue :toggle="nav_toggle" :team="'all'" :header="'Partner Omzet'" />
        <div class="item">
          <transition-group name="list-partner">
            <PartnerList2 v-for="(item, index) in all_c" :key="item.partner_id" :toggle="nav_toggle" :item="item"
              :index="index" :rank="28" class="row-partners list-partner-item"
              :class="getRowComplianceClass(item.compliance)" />
          </transition-group>
        </div>
      </div>

       <div v-if="all_e.length > 0" class="c block" :class="{last: all_e.length > 0 && active_slide == all_e.length}">
        <div class="col-header header-tabs">
          <template v-for="item in all_e" :key="item.tab_index">
            <h1 v-if="showSlide(item.tab_index, active_slide)" :class="{ active: item.tab_index === active_slide }">
              {{ item.from }}-{{ item.to }}
            </h1>
          </template>
        </div>

          <template v-for="tab in all_e" :key="tab.tab_index">
              <div v-if="tab.tab_index === active_slide" class="item">
                <transition-group name="list-partner">
                  <PartnerList2 v-for="(item, index) in tab.slice" :key="item.partner_id" :toggle="nav_toggle" :item="item"
                    :index="index" :rank="tab.from - 1" class="row-partners list-partner-item"
                    :class="getRowComplianceClass(item.compliance)" />
                </transition-group>
              </div>
            </template>
      </div>

    </main>
    <Footer />

  </div>
</template>

<style></style>

<script>
import { useStore } from 'vuex'
import { computed, ref, onMounted } from 'vue'
import Mixin from '../shared/mixin';
import Revenue from '../components/Revenue.vue';
import Footer from '../components/Footer.vue';
import PartnerList2 from '../components/PartnerList2.vue';

export default {
  components: { Revenue, Footer, PartnerList2 },
  mixins: [Mixin],
  setup() {

    const active_slide = ref(1);
    const slide_count = ref(0);
    const nav_interval = ref(false);
    const nav_toggle = ref(true);

    const store = useStore()

    const all_a = computed(function () { return store.getters.partnerBig.slice(0, 28) })
    const all_c = computed(function () { return store.getters.partnerBig.slice(28, 45) })
    // const all_d = computed(function () { return store.getters.partnerBig.slice(45, 62) })
    const all_e = computed(function () {
      const firstElem = 45;
      const data = store.getters.partnerBig.slice(firstElem, -1);
      const chunks = Math.ceil(data.length / 28);
      const result = [];
      const chunksize = 28;
      for (let i = 1; i <= chunks; i += 1) {
        const start = (i * chunksize) - chunksize;
        const end = i * chunksize;
        const slice = data.slice(start, end);
        const from = firstElem + start + 1;
        const to = start + firstElem + chunksize;
        const tab = {
          tab_index: i, from, to, slice,
        };
        result.push(tab);
      }
      set_slide_count(result.length);
      // console.log(result);
      return result;
    })

    onMounted(() => {
      navigate();
    })

    function set_slide_count(value) {
      slide_count.value = value;
    }

    function showSlide(current, active) {
      const maxCount = 4;
      const slide = Math.ceil(current / maxCount);
      const start = maxCount * (slide - 1) + 1;
      const end = start + maxCount - 1;
      return active >= start && active <= end;
    }

    function navigate() {
      if (nav_interval.value === false) {
        nav_interval.value = setInterval(() => {
          nav_toggle.value = !nav_toggle.value
          // console.log(nav_toggle.value)
          if (nav_toggle.value === true) {
            if (active_slide.value !== slide_count.value && slide_count.value > 0) {
              active_slide.value += 1;
            } else {
              active_slide.value = 1;
            }
          }
        }, 12000);
      }
    }
    return { all_a, all_c, all_e, active_slide, showSlide, nav_toggle }
  }

}

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.8em;
}

.flex-cell {
  box-sizing: border-box;
  flex-grow: 1;
  width: 100%;
  padding: 0.8em 0.8em;
  overflow: hidden;
  list-style: none;
}

.flex-table {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.flex-row {
  border-top: 1px solid rgb(233, 233, 233);
}

.flex-row:hover {
  background-color: rgba(29, 173, 227, 0.1);
}

.cell-header {
  font-weight: bold;
}

.cell-left {
  text-align: left;
}

.cell-right {
  text-align: right;
}

.cell-spread {
  display: flex;
  justify-content: right;
}

.progress {
  width: 33%;
}

.name {
  white-space: nowrap;
  /* width: 20%; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.rank {
  width: 2%
}

.target {
  width: 12%;
}

.revenue {
  width: 100%;
  text-align: center;
}

.on-track {
  white-space: nowrap;
  width: 14%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

}

/* @media (max-width: 10em) {
        .on-track {
            display: none;
        }
        .last-flex-item {
            display: none;
        }
    } */
.outer {
  height: 100%;
}

.bar {
  border-radius: 1em;
  height: 100%;
}

.on-track-status {
  color: white;
  background-color: rgb(69, 228, 191);
  border-radius: 0.3em;
  /* width:50%; */
  height: 60%;
  padding: 0.2em 1em;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: bold;
}

.itsme {
  font-weight: 600;
  background-color: rgba(29, 173, 227, 0.1)
}


.blue-bg {
  background-color: rgb(30, 83, 188);
}

.blue-fg {
  color: rgb(30, 83, 188);
}

.white-fg {
  color: #ffffff;
}

.white-bg {
  background: #ffffff;
}

.shadow {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
}


.red-fg {
  color: rgb(248, 51, 60);
}

.red-bg {
  background-color: rgb(248, 51, 60);
}

.green-fg {
  color: rgb(69, 228, 191)
}

.green-bg {
  background-color: rgb(69, 228, 191);
}

.orange-fg {
  color: rgb(250, 154, 0);
}

.orange-bg {
  background-color: rgb(250, 154, 0);
}

.fa-arrow-down {
  margin-right: 0.4rem;
  color: rgb(248, 51, 60);
}
</style>
