<template>
    <div class="stats-page">
        <!-- <main class="team-cherries grid-3-cols"></main> -->
        <div class="left-block">
            <div v-for="(advertiser, index) in stats" :key="advertiser.advertiser_code">
                <div v-if="selectedAdv === -1 || selectedAdv === index">
                    <div v-for="project in advertiser.projects" :key="project.project">

                        <div class="left project">
                            <img class="logo" :src="advertiser.logo_url" />
                            <div>{{ project.description }}</div>
                        </div>


                        <div :total="total = project.items.find((el) => el.partner_id == 'totaal')"
                            class="stats-row header">
                            <div class="left"></div>
                            <div>sales</div>
                            <div>leads</div>
                            <div>conversie</div>
                            <div>uren</div>
                            <div>sales/uur</div>
                            <div>leads/uur</div>
                        </div>
                        <div :total="total = project.items.find((el) => el.partner_id == 'totaal')" class="stats-row total">
                            <div class="left">TOTAAL</div>
                            <div>{{ total.sales }}</div>
                            <div>{{ total.leads }}</div>
                            <div>{{ nf.format(total.conversion) }}%</div>
                            <div>{{ nf.format(total.hours) }}</div>
                            <div>{{ nf.format(total.sales_per_hour) }}</div>
                            <div>{{ nf.format(total.leads_per_hour) }}</div>
                        </div>

                        <div class="rows">
                            <div v-for="partner in filt(project.items)" :key="partner.partner_id" class="stats-row">
                                <div class="left">{{ partner.first_name }} {{ partner.last_name }}</div>
                                <div>{{ partner.sales }}</div>
                                <div>{{ partner.leads }}</div>
                                <div>{{ nf.format(partner.conversion) }}%</div>
                                <div>{{ nf.format(partner.hours) }}</div>
                                <div>{{ nf.format(partner.sales_per_hour) }}</div>
                                <div>{{ nf.format(partner.leads_per_hour) }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="right-block">

            <div v-if="targets.length > 0" class="col-2 col">
                <div>
                    <h1>Doelstellingen Cherries</h1>
                </div>
                <div class="grid-3-cols">
                    <RevenueBlock v-for="item in targets.slice(0, 3)" :key="item.advertiser" :toggle="nav_toggle"
                        :header="item.advertiser" :revenue="item.scores" :targets="item.targets" :headerLow="true" />
                </div>
            </div>
            <TopPartners :certifiedPartnerId="'28835'" :thisTeam="'Cherries'" :mode="'count'" />
        </div>

        <!-- {{ contacts() }} -->

        <footer>
            <div class="footer-start-block">
                <!-- <img src="@/assets/images/nos-logo.png" /> -->
            </div>
            <div class="footer-content">
                <!-- <div class="footer-content"></div> -->
                <p><template v-for="(advertiser, index) in stats" :key="advertiser.advertiser_code">
                        <span class="footer-hover" :class="{ 'active': index === selectedAdv }"
                            @click="clickAdvertiser(index)">{{ advertiser.advertiser }}</span>
                    </template>

                    <span class="footer-hover" :class="{ 'active': selectedAdv === -1 }" @click="
                        clickAdvertiser(-1)">Alles</span>
                </p>

            </div>
            <div class="footer-end-block">
                <p id="time">{{ currentTime }}</p>
                <a ref="downloadLink" @click="download">
                    <img :src="footerLogo" />
                </a>
            </div>
        </footer>
    </div>
</template>

<script>
import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useFetch } from '@/composables/useFetch';
import TopPartners from '../components/TopPartners.vue';
import RevenueBlock from '../components/RevenueBlock.vue'
import useTargets from '../composables/useTargets';
export default {
    name: 'StatsCherries',
    components: {
        TopPartners,
        RevenueBlock
    },
    setup() {

        const selectedAdv = ref(-1);

        const store = useStore();
        const { targets } = useTargets('28835')
        const nav_interval = ref(false);


        function navigate() {
            if (nav_interval.value === false) {
                nav_interval.value = setInterval(() => {
                    if (selectedAdv.value > -1) {
                        {
                            if (selectedAdv.value < stats.value.length - 1) {
                                selectedAdv.value += 1;
                            } else {
                                selectedAdv.value = 0;
                            }
                        }
                    }
                }, 12000);
            }
        }

        const nf = new Intl.NumberFormat('nl-NL',
            {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            });
        const loadStats = () => {
            const dataStats = useFetch(process.env.VUE_APP_STATS).then((response) => response.json()).catch((err) => { console.log(err) });
            const dataQP = useFetch(process.env.VUE_APP_QP).then((response) => response.json()).catch((err) => { console.log(err) });
            Promise.all([dataStats, dataQP]).then((fetched) => {
                store.dispatch('setStatsCherries', fetched[0]);
                store.dispatch('setQP', fetched[1]);
            });
        }

        const clickAdvertiser = (selected_index) => {
            selectedAdv.value = selected_index;
            // reset interval and start over with new interval, but take off where you left.
            clearInterval(nav_interval.value);
            nav_interval.value = false;
            navigate()
        }

        const filt = (item) => {
            return item.filter((el) => el.partner_id !== 'totaal')
        }

        const stats = computed(() => {
            return store.state.statsCherries.reduce((acc, cur) => {

                const adv_code = cur.project.substring(0, 4)
                const accadv = acc.find((el) => el.advertiser_code === adv_code)

                if (accadv) {
                    const project = accadv.projects.find((el) => el.project == cur.project)
                    if (project) {
                        project.items.push(getRowData(cur))
                    } else {
                        accadv.projects.push(getProject(cur))
                    }
                } else {
                    const adv = store.state.advertisers.find((el) => el.code === adv_code)
                    console.log(adv)
                    acc.push({
                        'advertiser_code': adv.code,
                        'advertiser': adv.name,
                        'logo_url': adv.logo_url,
                        'projects': [getProject(cur)]
                    })
                }

                return acc;
            }, []).sort(
                (a, b) => b.advertiser - a.advertiser
            )
        })

        const currentTime = computed(function () {
            return store.state.current_time;
        });


        const footerLogo = computed(function () {
            const theme = store.state.theme;
            if (theme == 'improvers') {
                return 'https://improvers-prod-images-bucket.s3.eu-central-1.amazonaws.com/logo/rgb_im_white.svg';
            } else {
                return 'https://improvers-prod-images-bucket.s3.eu-central-1.amazonaws.com/logo/rgb_' + theme + '_beeldmerk_square_white.svg';
            }
        });


        const getProject = (cur) => {
            const qpdata = store.state.qp.find((el) => el.code === cur.project.substring(0, 6))
            return { 'project': cur.project, 'description': qpdata.description, 'items': [getRowData(cur)] }
        }

        const getRowData = (item) => {

            const partner = store.state.contacts.find((el) => el.ext_sf_partner_id === item.partner_id)
            let sales = [];
            if (item.partner_id !== 'totaal') {
                sales = store.state.details.filter((el) => el.partner_id === item.partner_id && item.project === el.project_code)
            } else {
                sales = store.state.details.filter((el) => item.project === el.project_code && el.certified_partner_id === '28835')
            }

            const first_name = partner ? partner.first_name : 'none'
            const last_name = partner ? partner.last_name : 'none'
            const sales_count = sales ? sales.length : 0
            const conversion = item.leads > 0 ? (sales_count / item.leads) * 100 : 0;
            const sales_per_hour = sales ? sales.length / Math.max(item.hours, 1) : 0
            // console.log(conversion)
            return {
                ...item,
                'first_name': first_name,
                'last_name': last_name,
                'sales': sales_count,
                'conversion': conversion,
                'sales_per_hour': sales_per_hour
            }
        }



        onMounted(function () {
            loadStats()
            navigate()
        })

        return { stats, filt, nf, targets, currentTime, footerLogo, clickAdvertiser, selectedAdv }
    }
}
</script>

<style style="scoped">
footer .footer-start-block img {
    width: 120px;
}

footer .footer-content {
    padding: 0 40px;
}

.stats-page footer .footer-content p {
    animation: unset;
}

/* .col-header {
    grid-template-columns: auto 18% 18%;
} */


.stats-page .col-header {
    grid-template-columns: auto 15% 15%;
}

.stats-row {
    display: grid;
    background-color: rgba(44, 44, 44, 0.5);
    grid-template-columns: 20% 10% 10% 10% 10% 10% 10%;
    padding: 10px;
}

.stats-page .rows {
    margin-bottom: 30px;
    font-size: 1.2em;
}

.footer-hover:hover {
    cursor: pointer;
    color: rgba(255, 255, 255);
}

.footer-hover.active {
    color: rgba(255, 255, 255);
}

.footer-hover {
    color: rgba(255, 255, 255, 0.452);
}

.stats-page .single-chart {
    width: 80%;
}

.stats-row.header {
    text-transform: uppercase;
}

.stats-row.header div {
    opacity: 0.3
}

.stats-row.total {
    font-size: 2em;
    font-weight: bold;
}

.stats-page {
    display: grid;
    grid-template-columns: 59.5% 39.5%;
    padding: 20px;
    justify-content: space-between;
}

.stats-page .row-partners {
    grid-template-columns: 4% auto 10% 15% 15%;
}

.stats-page .left {
    text-align: left;
}

.stats-page .project {
    display: grid;
    grid-template-columns: 10% 90%;
    text-transform: uppercase;
    align-items: end;
    padding: 10px;
    background-color: rgba(44, 44, 44, 0.5);
}

.center {
    text-align: center;
}

.stats-page .logo {
    height: 2em;
}

.stats-page .project {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 20px;
}

.left-block {
    grid-column: 1;
}

.right-block {
    grid-column: 2;
    text-align: left;
}

.grid-2-cols {
    display: grid;
    grid-template-columns: 45% 45%;
    grid-gap: 5%;
}

.revenue-block {
    margin-bottom: 10px;
}
</style>