<template>
  <div id="recent">
    <main class="laatste-sales">
      <div class="a block">
        <LastSale />
      </div>
      <div class="b block">
        <div class="block-wrapper">
          <!-- <transition name="slide-left"> -->
          <div v-if="active_slide === 0" class="recent b">
            <div class="b-slide slide-1">
              <div class="b1 block">
                <Announcement />
              </div>
              <div class="b2 block">
                <Revenue :toggle="nav_toggle" :team="'all'" :header="'Bruto partneromzet'" />
              </div>
              <div class="b3 block">
                <Birthday />
              </div>
            </div>
          </div>
          <!-- </transition> -->
          <!-- <transition name="slide-left"> -->
          <AdvertiserCherries :active-slide="active_slide" class="recent c" />
          <!-- </transition> -->
        </div>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import Revenue from '../components/Revenue.vue';
import Footer from '../components/Footer.vue';
import LastSale from '../components/LastSale.vue';
import Announcement from '../components/Announcement.vue';
import Birthday from '../components/Birthday.vue';
// import AdvertiserComponent from '../components/Advertiser.vue';
import AdvertiserCherries from '../components/AdvertiserCherries.vue';
// import { useRoute } from 'vue-router'
import { onMounted, ref } from 'vue'
// import shared from '../shared/shared.js';

export default {
  components: {
    Footer,
    Revenue,
    LastSale,
    Announcement,
    Birthday,
    AdvertiserCherries,
  },
  setup() {
    const active_slide = ref(0);
    const nav_interval = ref(false);
    const nav_toggle = ref(true);

    // const router = useRoute();
    // const query = Object.assign({}, router);
    // console.log('query',router, query)

    function navigate() {
      if (nav_interval.value === false) {
        nav_interval.value = setInterval(() => {
          if (active_slide.value == 0 && nav_toggle.value == true) {
            nav_toggle.value = false
          } else {
            if (active_slide.value < 4) {
              active_slide.value += 1;
            } else {
              // console.log('reset')
              nav_toggle.value = true
              active_slide.value = 0;
            }
          }
        }, 12000);
      }
    }

    onMounted(() => {
      // active_slide.value = 1;
      navigate();
    })

    return { active_slide, nav_interval, nav_toggle }

  }
}

</script>
