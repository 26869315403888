export default function() {
  function getProgress(current, target) {
    return current > 0 ? Math.round((target / current) * 100) : 100;
  }

  function getStrokeDashArray(total, current) {
    const pct = `${Math.min(this.getProgress(total, current), 100)}`;
    return `${pct}, 100`;
  }

  function getSSEUrl(url, access_token) {
    const token = new URL(window.location.href).searchParams.get("token");
    if (token !== null) {
      return `${url}?token=${token}`;
    }
    if (access_token) {
      return `${url}?access_token=${access_token}`;
    }
    return url;
  }

  const months = [
    "Januari",
    "Februari",
    "Maart",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Augustus",
    "September",
    "Oktober",
    "November",
    "December",
  ];

  function getDateFormatted(date) {
    const year = date.getFullYear(); // 2019
    const day = date.getDate(); // 23
    const monthIndex = date.getMonth();
    const month = months[monthIndex];
    return `${day} ${month} ${year}`;
  }

  function capitalize(data) {
    return data.replace(/^(.)|\.\s(.)/g, function($1) {
      return $1.toUpperCase();
    });
  }

  function formatCompliance(value) {
    if (value === null) {
      return "";
    }
    return `${Math.round(value)}%`;
  }

  function getProduct(advertiser, productDescription) {
    // console.log('aadvertiser', advertiser, productDescription)
    if (productDescription === null || productDescription === "") {
      if (
        advertiser === "CWKO" ||
        advertiser === "CDVW" ||
        advertiser === "CMIN"
      ) {
        return "Donatie";
      }
      if (advertiser === "LVRL") {
        return "Deelname";
      }
    }
    const retval = productDescription
      .replace("ZekerheidsGarantie", "ZHG")
      .replace("Zekerheidsgarantie", "ZHG")
      .replace("Electriciteit", "Elektriciteit")
      .replace("Structureel", "Structurele donatie");
    return retval;
  }

  function chooseColor(size) {
    const colors = ["blue", "mint", "orange", "salsa"];
    return colors[size % 4];
  }

  function getItemComplianceClass(value) {
    if (value === null) {
      return "";
    }
    if (value >= 95) {
      return "compliance-pos";
    }
    if (value < 90) {
      return "compliance-neg";
    }
    return "compliance-med";
  }

  function getRowComplianceClass(value) {
    if (value === null) {
      return "";
    }
    if (value >= 95) {
      return "row-compliance-pos";
    }
    if (value < 90) {
      return "row-compliance-neg";
    }
    return "row-compliance-med";
  }

  function getInitials(first_name, last_name) {
    if (first_name && last_name) {
      const lastNameItems = last_name.split(" ");
      const t = lastNameItems.filter(
        (el) => el.substring(0, 1) === el.substring(0, 1).toUpperCase()
      );
      if (t.length > 0) {
        return `${first_name.substring(0, 1)}${t[0].substring(0, 1)}`;
      }
      return `${first_name.substring(0, 1)}${last_name
        .substring(0, 1)
        .toUpperCase()}`;
    }
    return "JD";
  }

  function getLogo(certified_partner_id) {
    if (certified_partner_id) {
      if (certified_partner_id === "28835") {
        return "cherries-brand-circle";
      } else {
        return "certified-brand-circle";
      }
    } else {
      return "improvers-brand-circle";
    }
  }

  function scale_for_value(value) {
    if (value <= 1) return 1;
    if (value <= 2) return 2.5;
    if (value <= 5) return 5;
    if (value <= 7.5) return 7.5;
    if (value <= 10) return 10;
    if (value <= 12.5) return 12.5;
    if (value <= 15) return 15;
    if (value <= 17.5) return 17.5;
    if (value <= 20) return 20;
    if (value <= 30) return 30;
    if (value <= 40) return 40;
    if (value <= 50) return 50;
    if (value <= 75) return 75;
    if (value <= 100) return 100;
    if (value <= 125) return 125;
    if (value <= 150) return 150;
    if (value <= 175) return 175;
    if (value <= 200) return 200;
    if (value <= 250) return 250;
    if (value <= 500) return 500;
    if (value <= 750) return 750;
    if (value <= 1000) return 1000;
    if (value <= 1250) return 1250;
    if (value <= 1500) return 1500;
    if (value <= 1750) return 1750;
    if (value <= 2000) return 2000;
    if (value <= 2500) return 2500;
    if (value <= 5000) return 5000;
    if (value <= 7500) return 7500;
    if (value <= 10000) return 10000;
    if (value <= 12500) return 12500;
    if (value <= 15000) return 15000;
    if (value <= 20000) return 20000;
    if (value <= 25000) return 25000;
    if (value <= 50000) return 50000;
    if (value <= 75000) return 75000;
    if (value <= 100000) return 100000;
    if (value <= 125000) return 125000;
    if (value <= 150000) return 150000;
    if (value <= 175000) return 175000;
    if (value <= 250000) return 250000;
    if (value <= 500000) return 500000;
    return value;
  }

  function formatFee(value) {
    // return Math.round(value).toLocaleString('nl-NL', { maximumFractionDigits: 2 }); // '1,234.57'
    return value.toLocaleString("nl-NL", {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }); // '1.234'
  }

  return {
    getProgress,
    getStrokeDashArray,
    chooseColor,
    getItemComplianceClass,
    getRowComplianceClass,
    getInitials,
    getProduct,
    getSSEUrl,
    formatFee,
    formatCompliance,
    capitalize,
    scale_for_value,
    getLogo,
    getDateFormatted,
  };
}
