<template>
 <div class="birthday">
  <div class="col-header2">
   <h1>Vandaag jarig</h1>
   <div class="birthdays">
    <div
     v-for="item in getBirthDays"
     :key="item.partner_id"
     class="birthday-card"
    >
     <div
      class="partner-foto"
      :class="chooseColor(item.first_name.length + item.last_name.length)"
     >
      {{ item.initials }}
     </div>
     <h2 class="name">{{ item.first_name }} {{ item.last_name }}</h2>
    </div>
   </div>
  </div>
 </div>
</template>

<script>
import Mixin from '../shared/mixin';

export default {
  name: 'Birthday',
  mixins: [Mixin],
  computed: {
    getBirthDays() {
      return this.$store.getters.getBirthDays;
    },
  },
};
</script>
