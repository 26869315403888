import store from "@/store";
import { computed } from "vue";

export const useShowMoneyValues = computed(() => {
  const theme = store.state.theme;
  if (theme === "improvers") {
    return true;
  }
  return false;
});
