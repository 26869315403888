<template>
    <div class="single-chart">
    <svg viewBox="0 0 36 36" class="circular-chart">
     <path
      class="circle-bg"
      d="M18 2.0845
                                                a 15.9155 15.9155 0 0 1 0 31.831
                                                a 15.9155 15.9155 0 0 1 0 -31.831"
     />
     <path
      class="circle"
      :stroke-dasharray="getStrokeDashArray(getTarget, getRevenue)"
      d="M18 2.0845
                                                a 15.9155 15.9155 0 0 1 0 31.831
                                                a 15.9155 15.9155 0 0 1 0 -31.831"
     />
     <text x="18" y="20.35" class="percentage">
      {{ getProgress(getTarget, getRevenue) }}%
     </text>
    </svg>
   </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import shared from '../shared/shared.js';

export default defineComponent({
    props: [
        'target',
        'revenue'
    ],
    setup(props) {
        const { getProgress, getStrokeDashArray} = shared()
        
        
        const getTarget = computed(function() {
            return props.target
        })

        const getRevenue = computed(function() {
            return props.revenue
        })

        return { getTarget, getRevenue, getProgress, getStrokeDashArray }
        
    },
})
</script>
